import { Row, Tooltip } from "antd";
import classNames from "classnames";
import { Delete, Detail } from "components/ActionTable/ActionTable";
import CustomAvatar from "components/CustomAvatar/CustomAvatar";
import CustomTable from "components/CustomTable";
import {
  ClientType,
  FormatTime,
  NavigateFromType,
  UserPermissionType,
} from "constants/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertTimeToLocal, formatSubCategoryLevel2 } from "utils/helper";
import styles from "./styles.module.scss";
import classnames from "classnames";
import useNavigateSearch from "utils/hooks/useNavigateSearch";
import { usePermissions } from "utils/hooks/usePermissions";
import MemberName from "components/MemberName";

interface IProps {
  data: any;
  isLoading: boolean;
  pageIndex: number;
  totalItems: number;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  changeUserStatus: (params: { userId: number; status: boolean }) => void;
  deleteOffer?: (id: number) => void;
  isDeleted?: boolean;
  memberId?: number | string;
}

export default function OfferTable({
  data,
  isLoading,
  pageIndex,
  totalItems,
  onPageChange,
  deleteOffer,
  isDeleted = false,
  memberId,
}: IProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const permissions = usePermissions();

  const goToProfile = (memberId: number | string) => {
    navigate(`/users/detail/${memberId}`);
  };

  const columns: any = [
    {
      title: t("tables.id"),
      key: "id",
      dataIndex: "id",
      width: 50,
    },
    {
      title: t("tables.userId"),
      key: "idUser",
      dataIndex: "memberProfile",
      width: 100,
      render: (value: any, record: any) => {
        const { memberId } = record?.memberProfile || {};
        return (
          <p className="cursor-pointer" onClick={() => goToProfile(memberId)}>
            {memberId}
          </p>
        );
      },
    },
    {
      title: t("rankingScreen.fullName"),
      dataIndex: "name",
      key: "fullName",
      render: (value: any, record: any) => {
        const { memberId, nickname, userId, avatar } =
          record?.memberProfile || {};
        return (
          <Tooltip title={t("common.nicknameUserId", { nickname, userId })}>
            <Row wrap={false} align="middle">
              <CustomAvatar avatar={avatar} className={styles.avatarTable} />
              <p
                onClick={() => goToProfile(memberId)}
                className={classnames("collapse-1 ml-8", "cursor-pointer")}
              >
                <MemberName nickname={nickname} userId={userId} />
              </p>
            </Row>
          </Tooltip>
        );
      },
      width: 250,
    },
    {
      title: t("tables.offer.subCategoriesLevel2"),
      dataIndex: "subCategory2",
      key: "subCategory2",
      render: (value: string, record: any) => {
        const { subCategories } = record || {};
        return (
          <Tooltip title={formatSubCategoryLevel2(subCategories)}>
            <span>{formatSubCategoryLevel2(subCategories)}</span>
          </Tooltip>
        );
      },
      width: 200,
    },
    {
      title: t("tables.offer.subCategoriesLevel3"),
      dataIndex: "subCategory3",
      key: "subCategory3",
      render: (value: string, record: any) => {
        const { subCategories } = record || {};
        return (
          <Tooltip title={formatSubCategoryLevel2(subCategories, 3)}>
            <span className={classNames("collapse-2")}>
              {formatSubCategoryLevel2(subCategories, 3)}
            </span>
          </Tooltip>
        );
      },
      width: 250,
    },
    {
      title: t("tables.offer.description"),
      key: "description",
      dataIndex: "description",
      width: 350,
      render: (value: string, record: any) => {
        return (
          <Tooltip title={value}>
            <span className={classNames("collapse-2")}>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("tables.createdAt"),
      key: "createdAt",
      dataIndex: "createdAt",
      render: (value: string, record: any) => {
        return (
          <span>{convertTimeToLocal(value, FormatTime.DATE_TIME_JP)}</span>
        );
      },
      width: 200,
    },
    ...(isDeleted
      ? [
          {
            title: t("tables.roleDelete"),
            key: "deleteType",
            dataIndex: "deleteType",
            width: 100,
            render: (value: any, record: any) => {
              return (
                <p>
                  {value === ClientType.ADMIN
                    ? t("common.admin")
                    : t("common.member")}
                </p>
              );
            },
          },
        ]
      : []),
    {
      title: t("tables.action"),
      key: "action",
      align: "center",
      render: (value: any, record: any, index: number) => {
        return (
          <Row align={"middle"} justify={"center"}>
            <Detail
              onClick={() => {
                navigateSearch(`/offers/detail/${record?.id}`, {
                  type: memberId
                    ? NavigateFromType.USER
                    : NavigateFromType.OFFER,
                });
              }}
            />
            {!isDeleted &&
              !memberId &&
              permissions.includes(UserPermissionType.OFFER_EDIT) && (
                <Delete
                  textConfirm={t("popConfirm.deleteTitle")}
                  onDelete={() => {
                    deleteOffer?.(record?.id);
                  }}
                />
              )}
          </Row>
        );
      },
      width: 200,
    },
  ];

  return (
    <CustomTable
      columns={columns}
      dataSource={data}
      isLoading={isLoading}
      onPageChange={onPageChange}
      pageIndex={pageIndex}
      totalItems={totalItems}
    />
  );
}
