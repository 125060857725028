import { Menu } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { images } from "assets/images";
import { UserPermissionType } from "constants/enums";
import { usePermissions } from "utils/hooks/usePermissions";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import styles from "./styles.module.scss";

const getVisibleItem = (item: any) => {
  return item.filter((item: any) => {
    return item.visible;
  });
};

export default function SideNav() {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { collapsed } = useToggleSideNav();
  const [selectedKey, setSelectedKey] = useState("doctors");
  const permissions = usePermissions();

  const items = useMemo(() => {
    return [
      {
        label: <Link to="/staffs">{t("nav.staffs")}</Link>,
        key: "staffs",
        icon: <img src={images.manageStaff} alt="" />,
        url: "/staffs",
        visible:
          permissions.includes(UserPermissionType.USER_EDIT) ||
          permissions.includes(UserPermissionType.USER_VIEW),
      },
      // {
      //   label: <Link to="/">{t("nav.dashboard")}</Link>,
      //   key: "dashboard",
      //   icon: <AreaChartOutlined />,
      //   url: "/",
      // },
      {
        label: <Link to="/users">{t("nav.users")}</Link>,
        key: "users",
        icon: <img src={images.manageUser} alt="" />,
        url: "/users",
        visible:
          permissions.includes(UserPermissionType.MEMBER_VIEW) ||
          permissions.includes(UserPermissionType.MEMBER_EDIT),
      },
      {
        label: t("nav.doctors.name"),
        key: "doctors-nav",
        icon: <img src={images.manageDoctor} alt="" />,
        visible:
          permissions.includes(UserPermissionType.DOCTOR_EDIT) ||
          permissions.includes(UserPermissionType.DOCTOR_VIEW),
        children: [
          {
            label: <Link to="/doctors">{t("nav.doctors.list")}</Link>,
            key: "doctors",
            url: "/doctors",
            visible: true,
          },
          {
            label: (
              <Link to="/doctors/request-doctor">
                {t("nav.doctors.request")}
              </Link>
            ),
            key: "request-doctor",
            url: "/doctors/request-doctor",
            visible: true,
          },
        ],
      },

      {
        label: t("nav.clinics.name"),
        key: "clinics-nav",
        icon: <img src={images.manageClinic} alt="" />,
        visible:
          permissions.includes(UserPermissionType.CLINIC_EDIT) ||
          permissions.includes(UserPermissionType.CLINIC_VIEW),
        children: [
          {
            label: <Link to="/clinics">{t("nav.clinics.list")}</Link>,
            key: "clinics",
            url: "/clinics",
            visible: true,
          },
          {
            label: (
              <Link to="/clinics/request-clinic">
                {t("nav.clinics.request")}
              </Link>
            ),
            key: "request-clinic",
            url: "/clinics/request-clinic",
            visible: true,
          },
        ],
      },
      // {
      //   label: <Link to="/banners">{t("nav.banners")}</Link>,
      //   key: "banners",
      //   icon: <LayoutOutlined />,
      //   url: "/banners",
      // },
      // {
      //   label: <Link to="/contact-us">{t("nav.contactUs")}</Link>,
      //   key: "contactUs",
      //   icon: <ContactsOutlined />,
      //   url: "/contactUs",
      //   visible: true,
      // },
      {
        label: <Link to="/admin-posts">{t("nav.postsAdmin")}</Link>,
        key: "admin-posts",
        icon: <img src={images.managePost} alt="" />,
        url: "/admin-posts",
        visible:
          permissions.includes(UserPermissionType.BEAUTY_INFO_EDIT) ||
          permissions.includes(UserPermissionType.BEAUTY_INFO_VIEW),
      },
      {
        label: <Link to="/posts">{t("nav.posts")}</Link>,
        key: "posts",
        icon: <img src={images.managePostCommunity} alt="" />,
        url: "/posts",
        visible:
          permissions.includes(UserPermissionType.COMMUNITY_VIEW) ||
          permissions.includes(UserPermissionType.COMMUNITY_EDIT),
      },
      {
        label: t("nav.reports.name"),
        key: "reports",
        icon: <img src={images.manageReport} alt="" />,
        visible:
          permissions.includes(UserPermissionType.REPORT_EDIT) ||
          permissions.includes(UserPermissionType.REPORT_VIEW),
        children: [
          {
            label: (
              <Link to="/reports/user">{t("nav.reports.listReportUser")}</Link>
            ),
            key: "reports-user",
            url: "/reports/user",
            visible: true,
          },
          {
            label: (
              <Link to="/reports/post">{t("nav.reports.listReportPost")}</Link>
            ),
            key: "reports-post",
            url: "/reports/post",
            visible: true,
          },
        ],
      },
      {
        label: <Link to="/offers">{t("nav.offers")}</Link>,
        key: "offers",
        icon: <img src={images.manageOffer} alt="" />,
        url: "/offers",
        visible:
          permissions.includes(UserPermissionType.OFFER_EDIT) ||
          permissions.includes(UserPermissionType.OFFER_VIEW),
      },
      {
        label: <Link to="/swipes">{t("nav.swipes")}</Link>,
        key: "swipes",
        icon: <img src={images.manageSwipe} alt="" />,
        url: "/swipes",

        visible:
          permissions.includes(UserPermissionType.SWIPE_EDIT) ||
          permissions.includes(UserPermissionType.SWIPE_VIEW),
      },
      {
        label: <Link to="/notifications">{t("nav.notifications")}</Link>,
        key: "notifications",
        icon: <img src={images.manageNotification} alt="" />,
        url: "/notifications",
        visible:
          permissions.includes(UserPermissionType.NOTI_EDIT) ||
          permissions.includes(UserPermissionType.NOTI_VIEW),
      },
      {
        label: t("nav.rankings.title"),
        key: "rankings-nav",
        icon: <img src={images.manageRanking} alt="" />,
        visible:
          permissions.includes(UserPermissionType.RANKING_EDIT) ||
          permissions.includes(UserPermissionType.RANKING_VIEW),
        children: [
          {
            label: (
              <Link to="/rankings">{t("nav.rankings.listRankingDoctors")}</Link>
            ),
            key: "rankings",
            url: "/rankings",
            visible: true,
          },
          {
            label: (
              <Link to="/rankings/category">
                {t("nav.rankings.rankingCategory")}
              </Link>
            ),
            key: "rankings-category",
            url: "/rankings/category",
            visible: true,
          },
        ],
      },
      {
        label: <Link to="/reviews">{t("nav.reviews")}</Link>,
        key: "reviews",
        icon: <img src={images.manageReview} alt="" />,
        url: "/reviews",

        visible:
          permissions.includes(UserPermissionType.REVIEW_EDIT) ||
          permissions.includes(UserPermissionType.REVIEW_VIEW),
      },

      {
        label: t("nav.masterData.title"),
        key: "masterData",
        icon: <img src={images.manageMasterData} alt="" />,
        visible:
          permissions.includes(UserPermissionType.MASTER_DATA_EDIT) ||
          permissions.includes(UserPermissionType.MASTER_DATA_VIEW),

        children: [
          {
            label: <Link to="/master-data/faq">{t("nav.masterData.faq")}</Link>,
            key: "faq",
            url: "/master-data/faq",
          },
          {
            label: (
              <Link to="/master-data/report">
                {t("nav.masterData.reportReason")}
              </Link>
            ),
            key: "report",
            url: "/master-data/report",
          },
          {
            label: (
              <Link to="/master-data/policy">{t("nav.masterData.policy")}</Link>
            ),
            key: "policy",
            url: "/master-data/policy",
          },
          {
            label: (
              <Link to="/master-data/terms">{t("nav.masterData.terms")}</Link>
            ),
            key: "terms",
            url: "/master-data/terms",
          },
          {
            label: (
              <Link to="/master-data/category-level2">
                {t("nav.masterData.categoryLevel2")}
              </Link>
            ),
            key: "category-level2",
            url: "/master-data/category-level2",
          },
          {
            label: (
              <Link to="/master-data/banners">
                {t("nav.masterData.banner")}
              </Link>
            ),
            key: "banner",
            url: "/master-data/banners",
          },
          {
            label: (
              <Link to="/master-data/campaign-member">
                {t("nav.masterData.campaign")}
              </Link>
            ),
            key: "campaign-member",
            url: "/master-data/campaign-member",
          },
        ],
      },
    ];
  }, [t, permissions]);

  useEffect(() => {
    items.forEach((item) => {
      if (location.pathname.startsWith(item.url || "###")) {
        setSelectedKey(item.key);
      }
      if (item.children) {
        item.children.forEach((childItem) => {
          if (location.pathname.startsWith(childItem.url || "###")) {
            setSelectedKey(childItem.key);
          }
        });
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const firstVisibleRoute: any = items.find((route) => route.visible);
    if (location.pathname === "/" && firstVisibleRoute?.visible) {
      if (firstVisibleRoute?.children) {
        const firstChildrenVisible = firstVisibleRoute?.children?.find(
          (route: any) => route.visible
        );
        navigate(firstChildrenVisible?.url);
      } else {
        navigate(firstVisibleRoute?.url);
      }
    }
  }, [location.pathname, permissions]);

  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 250, transition: "width 0.3s" }}
    >
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src={images.logo} alt="logo" className={styles.logo} />
        </Link>
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        defaultSelectedKeys={[]}
        className={styles.menu}
        items={getVisibleItem(items)}
      ></Menu>
    </div>
  );
}
