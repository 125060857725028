import { Dropdown, Select } from "antd";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined } from "@ant-design/icons";
import { LanguageType, LocalStorageCode } from "constants/enums";
import { logoutWithNotApi } from "utils/helper/authentication";
import useProfile from "utils/hooks/useProfile";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import configs from "constants/config";

export default function PageHeader() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { profile } = useProfile(true);
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    configs.DEBUG_MODE ? LanguageType.EN : LanguageType.JA
    // localStorage.getItem(LocalStorageCode.I18)
  );

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate("/mypage");
          }}
        >
          {t("common.profile")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            logoutWithNotApi();
          }}
        >
          {t("common.logout")}
        </div>
      ),
    },
  ];
  const handleSelectLanguageChange = (value: any) => {
    setCurrentLanguageCode(value);
    i18next.changeLanguage(value);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.actions}>
        {configs.APP_ENV === "dev" && (
          <Select
            className={styles.languages}
            onChange={handleSelectLanguageChange}
            value={currentLanguageCode}
          >
            <Select.Option value={LanguageType.JA}>
              {t("languages.japanese")}
            </Select.Option>
            <Select.Option value={LanguageType.EN}>
              {t("languages.english")}
            </Select.Option>
            <Select.Option value={LanguageType.VI}>
              {t("languages.vietnamese")}
            </Select.Option>
            <Select.Option value={LanguageType.KEY}>
              {t("languages.showKey")}
            </Select.Option>
          </Select>
        )}
        <div className={styles.menuWrapper}>
          <div className={styles.menuItem}>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <div>
                <span>{`${t("common.hello")} ${
                  profile?.data?.fullName || profile?.data?.name
                }!`}</span>
                &nbsp;
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
