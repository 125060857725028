import { DatePicker, TreeSelect } from "antd";
import HeaderContent from "components/HeaderContent";
import InputSearch from "components/SearchHelper/InputSearch";
import TitleSearch from "components/SearchHelper/TitleSearch";
import { useTranslation } from "react-i18next";
import { convertToCheckboxTree } from "utils/helper";

interface IProps {
  subCategory: any;
  handleSearch?: any;
  title?: string;
}

export default function OfferFilter({
  subCategory,
  handleSearch,
  title,
}: IProps) {
  const [t] = useTranslation();

  return (
    <HeaderContent title={title ? t(title) : ""}>
      <TitleSearch label={t("searchLabel.keyword")}>
        <InputSearch
          onSearchKeyword={handleSearch?.keywordSearch}
          placeholder={t("placeholders.keyword")}
        />
      </TitleSearch>
      <TitleSearch label={t("searchLabel.createdAt")}>
        <DatePicker.RangePicker
          className="input"
          onChange={handleSearch?.rangeDateSearch}
        />
      </TitleSearch>
      <TitleSearch label={t("searchLabel.category")}>
        <TreeSelect
          treeData={convertToCheckboxTree({ data: subCategory })}
          placeholder={t("placeholders.category")}
          className="select w-250"
          treeCheckable
          treeDefaultExpandAll={true}
          maxTagCount={"responsive"}
          showSearch={false}
          onChange={(data: any) => {
            handleSearch?.subCategoriesSearch(data);
          }}
          showArrow
          multiple
        />
      </TitleSearch>
    </HeaderContent>
  );
}
