import { useTranslation } from "react-i18next";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Divider, Image, Row, message } from "antd";
import {
  deleteOfferApi,
  getDetailMemberOfferApi,
  getDetailOfferApi,
} from "api/member";
import { images } from "assets/images";
import classNames from "classnames";
import { Delete } from "components/ActionTable/ActionTable";
import DetailRow from "components/DetailRow";
import HeaderContent from "components/HeaderContent";
import Loading from "components/Loading";
import MemberName from "components/MemberName";
import {
  AmendedBefore,
  NavigateFromType,
  QueryKey,
  UserPermissionType,
} from "constants/enums";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  convertTimeToLocal,
  formatAddressShow,
  formatOfferOrSwipeImages,
  formatSubCategoryLevel2,
} from "utils/helper";
import { usePermissions } from "utils/hooks/usePermissions";
import { useResourcesContext } from "utils/hooks/useResourcesContext";
import styles from "./styles.module.scss";

function DoctorsName({ doctors }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (!doctors.length)
    return <div className={styles.value}>{t("offer.no")}</div>;
  return (
    <div className={styles.value}>
      {doctors?.map((doctor: any, index: number) => {
        const { firstName = "", lastName = "", id, doctorId } = doctor || {};
        return (
          <span key={doctorId || id || index} className={styles.value}>
            <span
              onClick={() => {
                navigate(`/doctors/detail/${doctor?.doctorId}`);
              }}
              className="cursor-pointer"
            >{`${firstName} ${lastName}`}</span>
            {index === doctors.length - 1 ? "" : t("common.comma")}
          </span>
        );
      })}
      <span></span>
    </div>
  );
}
function OfferMemberName({ memberProfile }: any) {
  const navigate = useNavigate();
  const { nickname, userId, memberId } = memberProfile || {};
  return (
    <div
      className={classNames("cursor-pointer", styles.value)}
      onClick={() => {
        navigate(`/users/detail/${memberId}`);
      }}
    >
      <MemberName nickname={nickname} userId={userId} />
    </div>
  );
}

const OfferImageItem = ({ item }: any) => {
  return (
    <div className={styles.banner}>
      <Image
        src={item?.name || images.defaultImage}
        alt=""
        className={styles.cssBanner}
        fallback={images.defaultImage}
      />
    </div>
  );
};

export default function OfferDetail() {
  const permissions = usePermissions();
  const [searchParams] = useSearchParams();
  const navigateFromType = searchParams.get("type");
  const { t } = useTranslation();
  const resources = useResourcesContext();
  const { offerId } = useParams();
  const queryClient = useQueryClient();

  const { data: detailOffer, isLoading: loadingDetailOffer } = useQuery({
    queryKey: [QueryKey.DETAIL_OFFER, offerId],
    queryFn: () =>
      navigateFromType === NavigateFromType.USER
        ? getDetailMemberOfferApi(offerId)
        : getDetailOfferApi(offerId),
  });

  const { mutate: deleteOffer } = useMutation(
    (offerId: string | number) => deleteOfferApi(offerId),
    {
      onSuccess: () => {
        message.success(t("message.success"));
        queryClient.invalidateQueries([QueryKey.DETAIL_OFFER]);
      },
    }
  );

  const {
    id,
    images,
    subCategories,
    doctors,
    description,
    address,
    isMonitor = 0,
    startDate,
    endDate,
    amendedBefore,
    status,
    deleteType,
    memberProfile,
  } = detailOffer?.data || {};

  const imagesOffer = formatOfferOrSwipeImages(images || []);

  const deletedByRole: any = {
    1: t("common.member"),
    2: t("common.doctor"),
    3: t("common.clinic"),
    4: t("common.admin"),
  };

  return detailOffer ? (
    <div>
      {loadingDetailOffer && <Loading />}
      <HeaderContent
        hasBack
        title={t("offer.offerTitle")}
        childrenRight={
          permissions.includes(UserPermissionType.OFFER_EDIT) && (
            <Row align={"middle"} justify={"center"}>
              {!!status && navigateFromType !== NavigateFromType.USER && (
                <Delete
                  textConfirm={t("popConfirm.deleteTitle")}
                  onDelete={() => {
                    deleteOffer(id);
                  }}
                  classNameIcon="fs-18"
                />
              )}
            </Row>
          )
        }
      />

      <div className={styles.content}>
        <Divider orientation="left">{t("common.banner")}</Divider>
        <Row>
          <Image.PreviewGroup>
            {imagesOffer?.length ? (
              imagesOffer?.map((item: any) => {
                return <OfferImageItem item={item} key={item?.id} />;
              })
            ) : (
              <OfferImageItem item={{ name: memberProfile?.avatar }} />
            )}
          </Image.PreviewGroup>
        </Row>
        <div>
          <Divider orientation="left">{t("offer.detail")}</Divider>
          <DetailRow
            label={t("offer.member")}
            className={styles.detailRow}
            renderValue={<OfferMemberName memberProfile={memberProfile} />}
          />
          <DetailRow
            label={t("offer.subCategory")}
            value={formatSubCategoryLevel2(subCategories)}
          />
          <DetailRow
            label={t("offer.subCategory3")}
            value={formatSubCategoryLevel2(subCategories, 3)}
          />
          <DetailRow
            label={t("offer.subCategory4")}
            value={formatSubCategoryLevel2(subCategories, 4)}
          />
          <DetailRow
            label={t("offer.doctors")}
            className={styles.detailRow}
            renderValue={<DoctorsName doctors={doctors} />}
          />
          <DetailRow label={t("offer.description")} value={description} />
          <DetailRow
            label={t("offer.address")}
            value={formatAddressShow({
              countries: resources?.country,
              initAddress: address,
              provinces: resources?.province,
            })}
          />
          <DetailRow
            label={t("offer.time")}
            value={t("offer.rangeDate", {
              start: convertTimeToLocal(startDate),
              end: convertTimeToLocal(endDate),
            })}
          />
          <DetailRow
            label={t("offer.isMonitor")}
            value={isMonitor ? t("offer.yes") : t("offer.no")}
          />
          <DetailRow
            label={t("offer.amendedBefore")}
            value={
              amendedBefore === AmendedBefore.YES
                ? t("offer.yes")
                : t("offer.no")
            }
          />
          {!status && (
            <DetailRow
              label={t("common.deletedByRole")}
              value={deletedByRole[+deleteType]}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>no data</div>
  );
}
