import { Navigate, Outlet } from "react-router-dom";
import { Suspense } from "react";

import styles from "./styles.module.scss";
import storage from "utils/helper/storage";
import SideNav from "components/SideNav";
import PageHeader from "components/PageHeader";

export default function AuthWrapper() {
  const isAuthenticated = !!storage.getToken();

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div className={styles.mainWrapper}>
        <PageHeader />
        <div className={styles.pageContent}>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
