import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  activeMember,
  deleteMember,
  deleteOfferApi,
  getListMemberOfferApi,
  getListOffer,
} from "api/member";
import { CommonStatus, QueryKey } from "constants/enums";
import useFilter from "utils/hooks/useFilter";
import useResources from "utils/hooks/useResources";
import OfferFilter from "./components/OfferFilter";
import OfferTable from "./components/OfferTable";
import { defaultFilterExample } from "constants/defaultValues";
import { Tabs, TabsProps, message } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export default function OfferList(props: any) {
  const [t] = useTranslation();
  const { resources } = useResources();
  const { memberId } = props || {};
  const { subCategory } = resources || {};
  const queryClient = useQueryClient();

  const { filter, handleSearch, handlePageChange, handleFilterChange } =
    useFilter({
      memberId,
      status: 1,
      ...defaultFilterExample,
    });

  const itemTabs: TabsProps["items"] = [
    { key: `${CommonStatus.ACTIVE}`, label: t("posts.active") },
    { key: `${CommonStatus.INACTIVE}`, label: t("posts.deleted") },
  ];

  const { data: userList, isFetching: loadingOfferList } = useQuery(
    [QueryKey.OFFER_LIST, filter],
    () => (memberId ? getListMemberOfferApi(filter) : getListOffer(filter))
  );
  const onChangeStatus = async (userId: any, status: boolean) => {
    if (status) {
      return activeMember(userId);
    } else {
      return deleteMember(userId);
    }
  };

  const { mutate: changeUserStatus } = useMutation(
    ({ userId, status }: any) => onChangeStatus(userId, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.OFFER_LIST]);
      },
    }
  );

  const { mutate: deleteOffer } = useMutation(
    (offerId: string | number) => deleteOfferApi(offerId),
    {
      onSuccess: () => {
        message.success(t("message.success"));
        queryClient.invalidateQueries([QueryKey.OFFER_LIST]);
      },
    }
  );

  const handleChangeTab = (activeKey: string) => {
    handleFilterChange({ status: activeKey });
  };

  return (
    <div>
      <OfferFilter
        subCategory={subCategory}
        handleSearch={handleSearch}
        title={memberId ? "" : "offer.title"}
      />
      {memberId ? (
        <OfferTable
          data={userList?.data || []}
          isLoading={loadingOfferList}
          onPageChange={handlePageChange}
          pageIndex={Number(filter?.pageIndex)}
          totalItems={Number(userList?.totalItems)}
          changeUserStatus={changeUserStatus}
          deleteOffer={deleteOffer}
          isDeleted={false}
          memberId={memberId}
        />
      ) : (
        <div className="tab-container mt-16">
          <Tabs
            items={itemTabs}
            defaultActiveKey={filter.status}
            onChange={handleChangeTab}
            className={styles.tabs}
          />
          <OfferTable
            data={userList?.data || []}
            isLoading={loadingOfferList}
            onPageChange={handlePageChange}
            pageIndex={filter?.pageIndex || 1}
            totalItems={userList?.totalItems}
            isDeleted={filter.status === `${CommonStatus.INACTIVE}`}
            changeUserStatus={changeUserStatus}
            deleteOffer={deleteOffer}
          />
        </div>
      )}
    </div>
  );
}
