import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ConfigProvider } from "antd";

import RootWrapper from "wrappers/RootWrapper";
import { PermissionsProvider } from "utils/hooks/usePermissions";
import { ResourcesProvider } from "utils/hooks/useResourcesContext";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import jaJP from "antd/locale/ja_JP";
dayjs.locale("ja");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000,
      retry: false,
    },
  },
});

export const router = createBrowserRouter([
  // match everything with "*"
  { path: "*", element: <RootWrapper /> },
]);

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={jaJP}
        autoInsertSpaceInButton={false}
        theme={{
          token: {
            colorPrimary: "#87D6EA",
          },
        }}
      >
        <PermissionsProvider>
          <ResourcesProvider>
            <React.Suspense fallback={null}>
              <RouterProvider router={router} />
            </React.Suspense>
          </ResourcesProvider>
        </PermissionsProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}
