import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import vi from "./locales/vi.json";
import en from "./locales/en.json";
import ja from "./locales/ja.json";
import { LanguageType } from "constants/enums";
import configs from "constants/config";

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    // fallbackLng: "ja",
    lng: configs.DEBUG_MODE ? LanguageType.EN : LanguageType.JA,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: en },
      ja: { translation: ja },
      vi: { translation: vi },
    },
  });

export default i18n;
