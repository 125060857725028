export const MIN_LENGTH_PASSWORD = 8;

export const MAX_LENGTH_PASSWORD = 20;

export const MAX_LENGTH_USERNAME = 50;

export const MAX_IMAGE_POST_COMMUNITY = 4;

export const defaultFilterExample = {
  pageIndex: 1,
  pageSize: 10,
};

export const URL_UPLOAD_IMAGE = "https://kym-kiyomi-test.s3.amazonaws.com";

export const REGEX_HTML_TAGS = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

export const ALL_ADDRESS_ID = 0;
