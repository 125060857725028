import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import styles from "./styles.module.scss";
import { Button, Row } from "antd";
import { PlusOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

interface IProps {
  title: string;
  buttonAdd?: {
    title: string;
    onClick: () => void;
    isLoading?: boolean;
  };
  children?: ReactNode;
  childrenRight?: ReactNode;
  hasBack?: boolean;
  urlBack?: string;
  hasIconAdd?: boolean;
  className?: string;
}

function HeaderContent({
  title,
  buttonAdd,
  children,
  hasBack = false,
  urlBack,
  hasIconAdd = true,
  childrenRight,
  className,
}: IProps) {
  const navigate = useNavigate();

  const goBack = () => {
    if (urlBack) {
      navigate(urlBack);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={classNames([styles.container, className])}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
      </Helmet>
      <Row align="middle" justify="space-between">
        <Row align={"middle"}>
          {hasBack && (
            <LeftOutlined onClick={goBack} className="mr-8 text-20" />
          )}
          <h1 className={styles.title}>{title}</h1>
        </Row>
        {buttonAdd && (
          <Button
            type="primary"
            className="button"
            onClick={buttonAdd.onClick}
            loading={buttonAdd.isLoading}
          >
            {hasIconAdd && <PlusOutlined />} {buttonAdd.title}
          </Button>
        )}
        {childrenRight}
      </Row>
      {children && (
        <Row
          align="middle"
          justify="end"
          className={classNames("mt-10", [styles.search])}
        >
          {children}
        </Row>
      )}
    </div>
  );
}

export default HeaderContent;
