import { CommonStatus, CommonValue } from "constants/enums";
import i18n from "i18n/i18n";

export const defaultTake = 10;

export const text = {
  fieldIsRequired: "こちらは必須項目です。",
  twoPasswordDontMatch: "パスワードとパスワード再入力が異なっています。",
  validatePassword:
    "パスワードは8～20桁の半角文字、半角数字の2種類を組み合わせてください。",
  validateFullNameRegex: "名前には特殊文字を含むことはできません。",
  validateWhiteSpace: "こちらは必須項目です。",
};

export const optionsDropdownStatus = [
  { value: CommonValue.ALL, label: i18n.t("common.all") },
  { value: CommonStatus.INACTIVE, label: i18n.t("common.inactive") },
  { value: CommonStatus.ACTIVE, label: i18n.t("common.active") },
];
