import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getProfile = () => sendGet("/cms/profile");

export const postLogin = (payload: any) =>
  sendPost("/cms/admin-auth/login", payload);

export const deleteLogout = () => sendDelete("/auth/logout");

export const changePasswordApi = (payload: any) =>
  sendPut("cms/admin-auth/change-password", payload);

export const updateProfile = (params: any) => sendPut("/cms/profile", params);
