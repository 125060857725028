import managePost from "./archive-book.png";
import arrowLeftIcon from "./arrow-left-icon.png";
import manageReport from "./clipboard-close.png";
import defaultAvatar from "./default-avatar.png";
import defaultImage from "./default-image.png";
import manageDoctor from "./doctor 1.png";
import managePostCommunity from "./document-text.png";
import manageClinic from "./hospital.png";
import logo from "./logo.png";
import manageReview from "./message-favorite.png";
import manageOffer from "./note-favorite.png";
import manageNotification from "./notification-bing.png";
import manageStaff from "./profile-2user.png";
import manageUser from "./profile.png";
import manageMasterData from "./setting-2.png";
import manageRanking from "./star.png";
import manageSwipe from "./stickynote.png";

export const images = {
  arrowLeftIcon,
  defaultAvatar,
  defaultImage,
  logo,
  manageStaff,
  manageUser,
  manageDoctor,
  manageClinic,
  managePost,
  manageSwipe,
  manageMasterData,
  manageRanking,
  manageReview,
  manageNotification,
  managePostCommunity,
  manageReport,
  manageOffer,
};
