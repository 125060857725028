import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import OfferDetail from "pages/Offer/OfferDetail";
import OfferList from "pages/Offer/OfferList";
import useResources from "utils/hooks/useResources";
import AuthWrapper from "wrappers/AuthWrapper";

const DoctorList = lazy(() => import("pages/Doctors/DoctorList"));
const DoctorListRequest = lazy(() => import("pages/Doctors/DoctorListRequest"));
const DoctorDetail = lazy(() => import("pages/Doctors/DoctorDetail"));
const UserList = lazy(() => import("pages/Users/UserList"));
const UserDetail = lazy(() => import("pages/Users/UserDetail"));
const SwipeList = lazy(() => import("pages/Swipe/SwipeList"));
const SwipeDetail = lazy(() => import("pages/Swipe/SwipeDetail"));
const ClinicList = lazy(() => import("pages/Clinics/ClinicList"));
const ClinicListRequest = lazy(() => import("pages/Clinics/ClinicListRequest"));
const ClinicDetail = lazy(() => import("pages/Clinics/ClinicDetail"));
const Mypage = lazy(() => import("pages/Mypage"));
const PostAdminList = lazy(() => import("pages/PostsAdmin/PostAdminList"));
const PostAdminDetail = lazy(() => import("pages/PostsAdmin/PostAdminDetail"));
const PostList = lazy(() => import("pages/Posts/PostList"));
const PostDetail = lazy(() => import("pages/Posts/PostDetail"));
const Login = lazy(() => import("pages/Login"));
const NotFound = lazy(() => import("pages/NotFound"));
const AccessDenied = lazy(() => import("pages/AccessDenied"));
const Notifications = lazy(() => import("pages/Notifications"));
const NotificationsAdd = lazy(
  () => import("pages/Notifications/components/AddNotification")
);
const NotificationsEdit = lazy(
  () => import("pages/Notifications/components/EditNotification")
);
const ListRankingDoctors = lazy(
  () => import("pages/Ranking/ListRankingDoctors")
);
const ListRankingCategories = lazy(
  () => import("pages/Ranking/ListRankingCategories")
);
const ListReportUser = lazy(() => import("pages/Reports/ListReportUser"));
const ListReportPost = lazy(() => import("pages/Reports/ListReportPost"));
const ListMasterDataReport = lazy(
  () => import("pages/Reports/MasterDataReport")
);
const Staffs = lazy(() => import("pages/Staffs"));
const FAQs = lazy(() => import("pages/FAQs"));
const Policy = lazy(() => import("pages/Policy"));
const Terms = lazy(() => import("pages/Terms"));
const CategoryLevelTwo = lazy(() => import("pages/Category/CategoryLevel2"));
const Banner = lazy(() => import("pages/Banners/BannerList"));
const CampaignMember = lazy(() => import("pages/CampaignMember"));
const ListReview = lazy(() => import("pages/Review"));
const DetailReview = lazy(() => import("pages/Review/DetailReviewScreen"));

export default function RootWrapper() {
  useResources();

  return (
    <Routes>
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/staffs" element={<Staffs />} />

        <Route path="/users" element={<UserList />} />
        <Route path="/users/detail/:userId" element={<UserDetail />} />

        {/* DOCTOR */}
        <Route path="/doctors" element={<DoctorList />} />
        <Route path="/doctors/detail/:doctorId" element={<DoctorDetail />} />
        <Route path="/doctors/request-doctor" element={<DoctorListRequest />} />
        <Route
          path="/doctors/request-doctor/detail/:doctorId"
          element={<DoctorDetail />}
        />

        {/* CLINIC */}
        <Route path="/clinics" element={<ClinicList />} />
        <Route path="/clinics/detail/:clinicId" element={<ClinicDetail />} />
        <Route path="/clinics/request-clinic" element={<ClinicListRequest />} />
        <Route
          path="/clinics/request-clinic/detail/:clinicId"
          element={<ClinicDetail />}
        />

        {/* POST */}
        <Route path="/posts" element={<PostList />} />
        <Route path="/posts/detail/:postId" element={<PostDetail />} />
        <Route path="/admin-posts" element={<PostAdminList />} />
        <Route
          path="/admin-posts/detail/:postId"
          element={<PostAdminDetail />}
        />

        {/* REPORT */}
        <Route path="/reports/user" element={<ListReportUser />} />
        <Route path="/reports/post" element={<ListReportPost />} />

        {/* OFFER */}
        <Route path="/offers" element={<OfferList />} />
        <Route path="/offers/detail/:offerId" element={<OfferDetail />} />

        {/* SWIPE */}
        <Route path="/swipes" element={<SwipeList />} />
        <Route path="/swipes/detail/:swipeId" element={<SwipeDetail />} />

        {/* NOTIFICATION */}
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/notifications/add" element={<NotificationsAdd />} />
        <Route path="/notifications/:id" element={<NotificationsEdit />} />

        {/* RANKING */}
        <Route path="/rankings" element={<ListRankingDoctors />} />
        <Route path="/rankings/category" element={<ListRankingCategories />} />

        {/* REVIEW */}
        <Route path="/reviews" element={<ListReview />} />
        <Route path="/reviews/detail/:reviewId" element={<DetailReview />} />

        {/* MASTER DATA */}
        <Route path="/master-data/faq" element={<FAQs />} />
        <Route path="/master-data/report" element={<ListMasterDataReport />} />
        <Route path="/master-data/policy" element={<Policy />} />
        <Route path="/master-data/terms" element={<Terms />} />
        <Route
          path="/master-data/category-level2"
          element={<CategoryLevelTwo />}
        />
        <Route path="/master-data/banners" element={<Banner />} />
        <Route
          path="/master-data/campaign-member"
          element={<CampaignMember />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="access-denied" element={<AccessDenied />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
