import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { postLogin, deleteLogout } from "api/auth";
import storage from "./storage";
import { router } from "App";

export const useLogin = () => {
  const navigate = useNavigate();

  const { mutate: login, isLoading: loadingLogin } = useMutation(
    (payload: any) => postLogin(payload),
    {
      onSuccess: (data: any) => {
        const token = data?.data?.token;
        const refreshToken = data?.data?.refreshToken;
        if (token && refreshToken) {
          storage.setToken(token);
          storage.setRefreshToken(refreshToken);
        }
        navigate("/");
      },
    }
  );

  return { login, loadingLogin };
};

export const useLogout = () => {
  const navigate = useNavigate();

  const { mutate: logout, isLoading: loadingLogout } = useMutation(
    () => deleteLogout(),
    {
      onSuccess: () => {
        storage.clearToken();
        storage.clearRefreshToken();
        navigate("/login", { replace: true });
      },
    }
  );

  return { logout, loadingLogout };
};

export const logoutWithNotApi = () => {
  storage.clearToken();
  storage.clearRefreshToken();
  router.navigate("/login");
};
