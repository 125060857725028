import { defaultFilterExample } from "constants/defaultValues";
import { CommonValue, LanguageType } from "constants/enums";
import { IFilter } from "constants/interfaces";
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface.d";
import { useState } from "react";
import { convertDateToStartEndOfDay, convertTimeToUTC } from "utils/helper";

export default function useFilter(defaultFilter?: IFilter) {
  const [headersRequest, setHeadersRequest] = useState({
    "Accept-Language": LanguageType.JA,
  });
  const [filter, setFilter] = useState<IFilter>(
    defaultFilter ?? defaultFilterExample
  );

  const handleFilterChange = (changeValue: IFilter = {}) => {
    setFilter({
      ...filter,
      ...changeValue,
      pageIndex: 1,
    });
  };

  const paramsSearch = (params: {
    [key: string]: string | number | undefined | any[];
  }) => {
    handleFilterChange({ ...params });
  };

  const keywordSearch = (value: string) => {
    handleFilterChange({ keyword: value });
  };

  const dateSearch = (value: string) => {
    handleFilterChange({ date: value });
  };

  const createdAtSearch = (date: Moment | null, dateString: string) => {
    handleFilterChange({ createdAt: convertTimeToUTC(date) });
  };
  const rangeDateSearch = (
    dateRange: RangeValue<any> | null,
    formatString: [string, string]
  ) => {
    return handleFilterChange({
      startDate: dateRange?.[0]?.startOf("day")?.toISOString(),
      endDate: dateRange?.[1]?.endOf("day")?.toISOString(),
    });
  };

  const createdAtToStartEndSearch = (date: Moment | null) => {
    handleFilterChange(convertDateToStartEndOfDay(date));
  };

  const statusSearch = (value: any) => {
    if (value === CommonValue.ALL) {
      handleFilterChange({ status: undefined });
    } else {
      handleFilterChange({ status: value });
    }
  };

  const subCategoriesSearch = (value: any) => {
    handleFilterChange({ subCategoryIds: value });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setFilter({ ...filter, pageIndex: page, pageSize });
  };

  const resetFilter = () => {
    if (defaultFilter) {
      setFilter({ ...defaultFilter });
    } else {
      setFilter({ ...defaultFilterExample });
    }
  };

  const changeAcceptLanguage = (lng: LanguageType = LanguageType.JA) => {
    setHeadersRequest((prevState: any) => ({
      ...prevState,
      "Accept-Language": lng,
    }));
  };

  return {
    filter,
    handleFilterChange,
    handlePageChange,
    resetFilter,
    headersRequest,
    currentLanguage: headersRequest["Accept-Language"],
    setHeadersRequest,
    changeAcceptLanguage,
    handleSearch: {
      keywordSearch,
      createdAtSearch,
      statusSearch,
      dateSearch,
      rangeDateSearch,
      createdAtToStartEndSearch,
      paramsSearch,
      subCategoriesSearch,
    },
  };
}
