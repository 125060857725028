import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import moment, { Moment } from "moment";

import configs from "constants/config";
import { ALL_ADDRESS_ID } from "constants/defaultValues";
import {
  ClientType,
  ClinicStatus,
  FormatTime,
  LanguageType,
  NotificationTypeSend,
  OfferImageType,
} from "constants/enums";
import { IFormatAddressShow } from "constants/interfaces";
import { REGEX_IMG, REGEX_YOUTUBE_VIDEO } from "constants/regex";
import dayjs from "dayjs";
import i18n from "i18n/i18n";
import { isArray } from "lodash";

export const handleErrorMessage = (error: any, needFormatError = true) => {
  message.destroy();
  message.error(needFormatError ? getErrorMessage(error) : error);
  if (configs.APP_ENV !== "prod") {
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || "Something went wrong!";
};

//Table
export const getIndexTable = (
  pageIndex: number,
  pageSize: number,
  current: number
) => {
  return (pageIndex - 1) * pageSize + current + 1;
};

//Date time
export const convertTimeToLocal = (time?: string, format?: FormatTime) => {
  if (!time) return "";
  switch (i18n.language) {
    case LanguageType.JA:
      return dayjs(time)
        .format(format || FormatTime.DATE_JP)
        .toString();
    default:
      return dayjs(time)
        .format(format || FormatTime.DATE_REVERSE)
        .toString();
  }
};

export const changeLocale = (locale: string) => {
  return dayjs().locale(locale);
};

export const convertTimeToUTC = (dateTime: Moment | null) => {
  if (!dateTime) return undefined;
  return dateTime.toISOString();
};

//Format text
export const trimText = (str?: string) => {
  if (!str) return "";
  return str.trim().replace(/\s+/gm, " ");
};

//Base 64
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const checkFileOrigin = (file: any) => {
  return !!file?.originFileObj;
};

export const textStatus: any = {
  [ClinicStatus.ACTIVE]: i18n.t("status.clinic.active"),
  [ClinicStatus.INACTIVE]: i18n.t("status.clinic.inactive"),
  [ClinicStatus.ADMIN_NOT_VERIFY]: i18n.t("status.clinic.notVerify"),
  [ClinicStatus.NOT_COMPLETE_REGISTER]: i18n.t(
    "status.clinic.uncompletedRegister"
  ),
  [ClinicStatus.REJECTED]: i18n.t("status.clinic.reject"),
};

export const monthFormat = "YYYY/MM";
export const YYYYMMDD = "YYYY/MM/DD";

export const formatDate = "YYYY/MM/DD hh:ss";

export const formatDateString = (
  date: Date | string | number,
  defaultFormat = formatDate
) => {
  if (!date) return "";
  return `${dayjs(date).format(defaultFormat)}`;
};

export const convertDateToStartEndOfDay = (dateTime: any) => {
  return {
    startDate: moment().startOf(dateTime).toISOString(),
    endDate: moment().endOf(dateTime).toISOString(),
  };
};

export const formatContentMentions = (content: string, isTooltip = false) => {
  const outputString = content.replace(
    /@\[(.*?)\]\((.*?)\)/g,
    (match, name, id_type) => {
      const [id, type] = id_type.split("_");
      let href = "";
      switch (type) {
        case ClientType.MEMBER.toString():
          href = `/users/detail/${id}`;
          break;
        case ClientType.DOCTOR.toString():
          href = `/doctors/detail/${id}`;
          break;
        case ClientType.CLINIC.toString():
          href = `/clinics/detail/${id}`;
          break;
        default:
          href = "#";
      }
      return isTooltip
        ? `@${name}`
        : `<a href="${href}" target="_blank" className={'mention-name'}>${name}</a>`;
    }
  );

  return outputString;
};
export const formatCurrencyJapanese = (
  value: number,
  options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "JPY",
  }
) => {
  return Number(value).toLocaleString("ja-JP", options);
};

export const replaceWithBr = (haiku: string) => {
  return haiku.replace(/\n/g, "<br />");
};

export const abbreviateNumber = (value: any): string => {
  if (!Number.isFinite(value)) {
    return "";
  }
  if (value < 0) {
    return `-${abbreviateNumber(-value)}`;
  }
  if (value < 1000) {
    return value.toString();
  }
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixIndex = Math.floor(Math.log10(value) / 3);
  const shortValue = (value / 10 ** (suffixIndex * 3))
    .toFixed(2)
    .replace(/\.?0+$/, "");
  return `${shortValue}${suffixes[suffixIndex]}`;
};

export const getObjectSendNotification = (type: number) => {
  if (type === NotificationTypeSend.ALL) {
    return i18n.t("notifications.typeSend.all");
  }
  if (type === NotificationTypeSend.CLINIC) {
    return i18n.t("notifications.typeSend.clinic");
  }
  if (type === NotificationTypeSend.DOCTOR) {
    return i18n.t("notifications.typeSend.doctor");
  }
  if (type === NotificationTypeSend.MEMBER) {
    return i18n.t("notifications.typeSend.member");
  }
  return i18n.t("notifications.typeSend.specifiedUser");
};

export const getDeletedByClientType = (clientType?: ClientType | null) => {
  if (!clientType) return "";
  const indexOfS = Object.values(ClientType).indexOf(clientType);
  const key = Object.keys(ClientType)[indexOfS]?.toLocaleLowerCase();
  return key || "";
};

export const isImage = (url: string) => {
  const isValidImage = url.match(REGEX_IMG) !== null;
  return isValidImage;
};

export const formatSubCategoryShow = (subCategories = [], level = 2) => {
  const newCategories =
    subCategories?.filter(
      (itemSubCategory: any) => itemSubCategory?.order === level
    ) || [];
  if (newCategories?.length) {
    const subCategoryShow = newCategories
      .map((item: any) => item?.title)
      .join(i18n.t("common.comma"));
    return subCategoryShow;
  }
  return "";
};

export const formatSubCategoryLevel2 = (subCategories = [], level = 2) => {
  const newCategories =
    subCategories?.filter(
      (itemSubCategory: any) => itemSubCategory?.subCategory?.order === level
    ) || [];

  if (newCategories?.length) {
    const subCategoryShow = newCategories
      .map((item: any) => item?.subCategory?.title)
      .join(i18n.t("common.comma"));
    return subCategoryShow;
  }
  return "";
};

export const addProvinceToDistrictsProfile = ({
  districts,
  provinces,
}: {
  districts: any;
  provinces: any;
}) => {
  const newDistricts = districts?.map((districtItem: any) => {
    return {
      ...districtItem,
      province:
        provinces.find(
          (provinceItem: any) => provinceItem.id === districtItem?.provinceId
        ) || {},
    };
  });
  return newDistricts;
};

export const isChooseAllCountry = ({ districts }: any) => {
  if (!districts?.length || !isArray(districts)) return false;
  const formatDistricts =
    typeof districts?.[0] === "object"
      ? districts?.map((item: any) => item?.id)
      : districts;
  return formatDistricts.includes(ALL_ADDRESS_ID);
};

export const formatAddressShow = (params?: IFormatAddressShow) => {
  const {
    countries = [],
    initAddress,
    commaLng = "common.comma",
    spaceLng = "common.dot",
    maxShow,
    provinces = [],
  } = params || {};

  if (isChooseAllCountry({ districts: initAddress })) {
    return countries
      .map((countryItem: any) => countryItem.name)
      .join(i18n.t(commaLng));
  }
  if (!isArray(initAddress)) return "";
  let newAddress = [...initAddress];
  if (initAddress) {
    newAddress = maxShow ? initAddress?.slice(0, maxShow) : initAddress;
  }
  const newDistricts = addProvinceToDistrictsProfile({
    districts: newAddress,
    provinces,
  });
  if (newDistricts?.length) {
    const addressShow = newDistricts
      .map(
        (item: any) =>
          `${item?.province?.name || ""}${
            item?.province?.name ? i18n.t(spaceLng) : ""
          }${item?.name || ""}`
      )
      .filter(Boolean)
      .join(i18n.t(commaLng));
    if (!maxShow || maxShow >= initAddress?.length) return addressShow;
    return `${addressShow}...`;
  }
  return "";
};

export const convertToCheckboxTree = ({ data }: { data: any }) => {
  if (!data) return [];
  const result: any = [];
  let dataByOrder = data;
  dataByOrder = data?.filter((item: any) => item.order === 2);
  dataByOrder?.forEach((item: any) => {
    const children: any = data
      ?.filter((itemChild: any) => itemChild?.parentId === item.id)
      ?.map((itemFilter: any) => ({
        value: itemFilter?.id,
        title: itemFilter?.title,
      }));
    const formattedItem: any = { value: item?.id, title: item?.title };

    if (children.length > 0) {
      formattedItem.children = children;
    }
    result.push(formattedItem);
  });
  return result;
};

export const convertCategoryLevel1Level2Tree = ({
  category,
  subCategory,
}: {
  category: any;
  subCategory: any;
}) => {
  const result: any = [];
  const level2 = subCategory?.filter((item: any) => item.order === 2);
  category?.forEach((item: any) => {
    const children: any = level2
      ?.filter((itemLevel2: any) => itemLevel2?.categoryId === item.id)
      ?.map((itemFilter: any) => ({
        value: itemFilter?.id,
        title: itemFilter?.title,
      }));
    const formattedItem: any = {
      value: `${item?.id}_category`,
      title: item?.title,
    };

    if (children.length > 0) {
      formattedItem.children = children;
    }
    result.push(formattedItem);
  });

  return result;
};

export const getIdVideoYoutube = (url: string) => {
  const match = url.match(REGEX_YOUTUBE_VIDEO);
  return match ? match[2] : "";
};

export const getEmbedYoutubeUrl = (url: string) => {
  const videoId = getIdVideoYoutube(url);
  return `https://www.youtube.com/embed/${videoId}`;
};

export const roleName = ({ record }: any) => {
  const roles = {
    1: record?.member,
    2: record?.doctor,
    3: record?.clinic,
  };
  const returnRoleName =
    Object.entries(roles).find(([roleKey, roleValue]) => Boolean(roleValue)) ||
    "";
  return returnRoleName;
};

export const formatOfferOrSwipeImages = (images: any) => {
  const beforePhotos: any[] = [];
  const afterPhotos: any[] = [];
  let formattedImages: any[] = [];
  images?.forEach((offerImage: any) => {
    if (offerImage.offerImageType === OfferImageType.BEFORE) {
      beforePhotos.push(offerImage);
    } else {
      afterPhotos.push(offerImage);
    }
  });
  formattedImages = [...beforePhotos, ...afterPhotos];
  return formattedImages;
};

export const formatSpaceURL = (url: string) => {
  const newURL = url?.replace(/\s/g, "");
  return newURL;
};
