import { sendDelete, sendGet, sendPut } from "./axios";

export const getListMember = (params: any) => sendGet("/cms/member", params);

export const deleteMember = (memberId?: number | string) =>
  sendDelete(`/cms/member/${memberId}`);

export const activeMember = (memberId?: number | string) =>
  sendPut(`/cms/member/active-member//${memberId}`);

export const getDetailMemberApi = (id?: number | string) =>
  sendGet(`/cms/member/${id}`);

export const getListOffer = (params: any) => sendGet("/cms/offer", params);

export const getDetailOfferApi = (id?: number | string) =>
  sendGet(`/cms/offer/${id}`);

export const deleteOfferApi = (offerId?: number | string) =>
  sendDelete(`/cms/offer/${offerId}`);

export const getListReviewMemberApi = (payload: any) =>
  sendGet(`/cms/member/reviews`, payload);

export const detailReviewMemberApi = (reviewId: number) =>
  sendGet(`/cms/member/reviews/${reviewId}`);

export const getListMemberOfferApi = (params: any) =>
  sendGet("/cms/member/offers", params);

export const getDetailMemberOfferApi = (id?: number | string) =>
  sendGet(`cms/member/offers/${id}`);
