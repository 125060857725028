import { LanguageType } from "constants/enums";
import { sendGet, sendPost, sendPut } from "./axios";

interface IAddResourceLanguage {
  resourceId: number;
  languageCode: LanguageType;
  name: string;
  value?: string;
}

export const getResources = () =>
  sendGet("/resources").then((data: any) => data.data);

export const getFAQMember = (payload: any, headers?: any) =>
  sendGet("/cms/resources/faq-member", payload, headers);

export const getFAQDoctor = (payload: any, headers?: any) =>
  sendGet("/cms/resources/faq-doctor", payload, headers);

export const getFAQClinic = (payload: any, headers?: any) =>
  sendGet("/cms/resources/faq-clinic", payload, headers);

export const addFAQ = (payload: any) => sendPost("/cms/resources/faq", payload);

export const updateFAQ = (id: number, payload: any) =>
  sendPut(`/cms/resources/faq/${id}`, payload);

export const onOffResource = (id: number, payload: { status: number }) =>
  sendPut(`/cms/resources/on-off/${id}`, payload);

export const getListReportReason = (payload: any, header?: any) =>
  sendGet("/cms/resources/report-reason", payload, header);

export const updateReportReason = (id: number, payload: any) =>
  sendPut(`/cms/resources/report-reason/${id}`, payload);

export const addReportReason = (payload: any) =>
  sendPost(`/cms/resources/report-reason`, payload);

export const getListTerms = (payload: any, headers?: any) =>
  sendGet(`/cms/resources/terms`, payload, headers);

export const updateTerm = (id: number, payload: any) =>
  sendPut(`/cms/resources/terms/${id}`, payload);

export const updateResourceById = (id: number, payload: any) =>
  sendPut(`/cms/resource/${id}`, payload);

export const updateStatusResource = (id: number, payload: any) =>
  sendPut(`cms/resource/update-status/${id}`, payload);

export const getListCategoryLevel2 = (payload: any) =>
  sendGet(`/cms/resources/sub-category`, payload);

export const updateStatusCategoryLevel2 = (id: number, payload: any) =>
  sendPut(`/cms/resources/sub-category/${id}`, payload);

export const getListCampaigns = (payload: any) =>
  sendGet(`/cms/resources/campaigns`, payload);

export const addCampaigns = (payload: {
  name: string;
  value: string;
  order: number;
}) => sendPost(`/cms/resources/campaigns`, payload);

export const addResourceLanguage = (params: IAddResourceLanguage) =>
  sendPost("/cms/resources/add-resource-language", params);

export const getListPolicy = (params: any, headers?: any) =>
  sendGet(`cms/resources/policy`, params, headers);

export const updatePolicy = (id: number, params: any) =>
  sendPut(`cms/resources/policy/${id}`, params);
