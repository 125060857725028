import { Input, InputProps } from "antd";
import Icon from "@ant-design/icons";
import { IconSearch } from "assets/images/icon";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import classNames from "classnames";

interface IProps extends InputProps {
  onSearchKeyword: (value: string) => void;
  placeholder: string;
  debounceTime?: number;
  sizeWidth?: number;
}

function InputSearch({
  onSearchKeyword,
  placeholder,
  debounceTime = 1000,
  className,
  sizeWidth = 300,
  ...otherProps
}: IProps) {
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      onSearchKeyword(value);
    },
    // delay in ms
    debounceTime
  );

  return (
    <Input
      className={classNames(`input w-${sizeWidth}`, [className])}
      onChange={(e) => debounced(e.target.value)}
      placeholder={placeholder}
      prefix={<Icon component={() => <IconSearch />} />}
      {...otherProps}
    />
  );
}

export default InputSearch;
